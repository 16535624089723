<template>
  <b-card
    class="salesrefund-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salesrefundForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款单编号"
            label-for="refund_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="refund_no"
              size="sm"
              v-model="salesrefund.refund_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="salesrefund.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="salesrefund.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货单ID"
            label-for="returnbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_id"
              size="sm"
              v-model="salesrefund.returnbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货单编号"
            label-for="returnbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_no"
              size="sm"
              v-model="salesrefund.returnbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单ID"
            label-for="statement_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_id"
              size="sm"
              v-model="salesrefund.statement_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单编号"
            label-for="statement_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_no"
              size="sm"
              v-model="salesrefund.statement_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="渠道ID"
            label-for="channel_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="channel_id"
              size="sm"
              v-model="salesrefund.channel_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="渠道"
            label-for="channel_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="channel_name"
              size="sm"
              v-model="salesrefund.channel_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库仓仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="salesrefund.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库仓仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="salesrefund.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款金额"
            label-for="refund_tatal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="refund_tatal"
              size="sm"
              v-model="salesrefund.refund_tatal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货日期"
            label-for="return_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="return_time"
              size="sm"
              v-model="salesrefund.return_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="salesrefund.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="salesrefund.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="salesrefund.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="salesrefund.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="salesrefund.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesrefundStore from './salesrefundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      salesrefund: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesrefund')) store.registerModule('salesrefund', salesrefundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesrefund')) store.unregisterModule('salesrefund')
    })

    const edit = function() {
      store.dispatch('salesrefund/edit', {id: this.id}).then(res => {
        this.salesrefund = res.data.data
      })
    }

    const view = function() {
      store.dispatch('salesrefund/view', {id: this.id}).then(res => {
        this.salesrefund = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('salesrefund/save', this.salesrefund).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
